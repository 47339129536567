// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'components' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Unauthorized from "containers/devise/unauthorized";
import Inactive from "containers/devise/inactive";
import Timeout from "containers/devise/timeout";
import WebpackerReact from "webpacker-react";

WebpackerReact.setup({
  Unauthorized,
  Inactive,
  Timeout
});
