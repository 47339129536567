import { colors } from "../color_constants";
import * as env from "../env";

export const deviseConstants = env => {
  switch (env) {
    case "development":
      return {
        backgroundColor: colors.RED,
        logo: "/logo-white.svg",
        env: "dev"
      };
    case "qa":
      return {
        backgroundColor: colors.BLACK,
        logo: "/logo-white-red.svg",
        env: "qa"
      };
    case "uat":
      return {
        backgroundColor: colors.GRAY,
        logo: "/logo-white.svg",
        env: "uat"
      };
    case "production":
      return {
        backgroundColor: colors.WHITE,
        logo: "/logo.png",
        env: "prod"
      };
  }
};
