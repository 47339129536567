import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { deviseConstants } from "../../../utils/devise_constants";

const propTypes = {
  access: PropTypes.string.isRequired,
  env: PropTypes.string.isRequired
};

const Unauthorized = props => {
  const [envConsts, setEnvConsts] = useState({
    env: "dev",
    backgroundColor: {},
    logo: {}
  });
  const [accessRequested, setAccessRequested] = useState(false);
  const [isButtonPressed, setButtonPressed] = useState(false);
  const [isUnsuccessful, setUnsuccessful] = useState(false);

  useEffect(() => {
    const setEnv = () => {
      const { env } = props;
      const values = deviseConstants(env);
      setEnvConsts(values);
    };
    setEnv();
  }, [props]);

  const renderEnv = () => {
    if (envConsts.env === "prod") return " ";
    return `${envConsts.env.toUpperCase()}`;
  };

  const submitAccess = () => {
    setButtonPressed(true);
    $.post({
      url: props.access,
      dataType: "text"
    })
      .success(data => setAccessRequested(true))
      .fail(error => {
        setAccessRequested(true);
        setUnsuccessful(true);
        console.error("Access request was unsuccessful: ", error);
      });
  };

  const { backgroundColor, env, logo } = envConsts;
  const bgStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: "-100",
    backgroundColor
  };
  const mainClass = classNames({
    "row pb-4 mt-5 mb-4": true,
    dev: env == "dev",
    staging: env == "staging",
    qa: env == "qa",
    prod: env == "prod",
    uat: env == "uat"
  });

  return (
    <Fragment>
      <div style={bgStyle} />
      <h5 className="environment-label" data-test="environment-label">
        {renderEnv()}
      </h5>
      <div className={mainClass}>
        <div className="col mt-5 offset-4">
          <div className="d-flex">
            <h1 className="studio">Studio</h1>
            <div className="learning-album d-flex">
              <div className="vertical-seperator mx-2">&nbsp;</div>
              <span>Learning Album</span>
            </div>
          </div>
          <h3 className="text unauthorized mt-3">Unauthorized (Inactive)</h3>
          <h5 className="text pt-3">
            You currently DO NOT have access to Learning Album Studio.
          </h5>
          <h5 className="text pt-3">
            Learning Album Studio is used by Verizon Global Learning &
            Development Instructional
            <br />
            Designers to create / author content for the Edge Learning Album
            application.
          </h5>
          <h5 className="text pt-3">
            STOP! If you are looking to review or deliver the content, please
            ask the author(s)
            <br />
            for the Edge URL instead and DO NOT request access.
          </h5>
          <h5 className="text pt-3">
            If you will be creating / authoring content, continue by selecting
            Request Access below.
          </h5>
          <p className="text mt-3">
            NOTE: Clicking the button below will create your profile and send an
            email to Learning Album
            <br />
            Support for review & approval. Support might reach out for some
            additional info depending on
            <br />
            your role / title but will respond within 24 hours either way.
          </p>
          {!accessRequested ? (
            <button
              className="btn btn-primary btn-lg my-2 request-access"
              disabled={isButtonPressed}
              onClick={() => {
                !isButtonPressed && submitAccess();
              }}
            >
              Request Access
              <i className="pl-4 fa fa-angle-right fa-lg" />
            </button>
          ) : (
            <h3 className="studio unauthorized mt-3">
              Request {!isUnsuccessful ? "Pending" : "Unsuccessful"}!
            </h3>
          )}
        </div>
      </div>
      <div
        className="fixed-bottom ml-4 pl-4 mb-4 pb-4 mr-auto"
        style={{ width: 230 }}
      >
        <img src={logo} className="logo" width="200" height="auto" />
      </div>
    </Fragment>
  );
};

Unauthorized.propTypes = propTypes;

export default Unauthorized;
