import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { deviseConstants } from "../../../utils/devise_constants";
import { accessRequestFormPath } from "../../../lib/paths";

const propTypes = {
  access: PropTypes.string.isRequired,
  env: PropTypes.string.isRequired
};

const Inactive = props => {
  const [envConsts, setEnvConsts] = useState({
    env: "dev",
    backgroundColor: {},
    logo: {}
  });
  const [isButtonPressed, setButtonPressed] = useState(false);

  useEffect(() => {
    const setEnv = () => {
      const { env } = props;
      const values = deviseConstants(env);
      setEnvConsts(values);
    };
    setEnv();
  }, [props]);

  const renderEnv = () => {
    if (envConsts.env === "prod") return " ";
    return `${envConsts.env.toUpperCase()}`;
  };

  const submitAccess = () => {
    setButtonPressed(true);
    $.post({
      url: props.access,
      dataType: "text"
    }).complete(() => {
      window.location.href = accessRequestFormPath();
    });
  };

  const { backgroundColor, env, logo } = envConsts;
  const bgStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: "-100",
    backgroundColor
  };
  const mainClass = classNames({
    "row pb-4 mt-5 mb-4": true,
    dev: env == "dev",
    staging: env == "staging",
    qa: env == "qa",
    uat: env == "uat",
    prod: env == "prod"
  });

  return (
    <Fragment>
      <div style={bgStyle} />
      <h5 className="environment-label" data-test="environment-label">
        {renderEnv()}
      </h5>
      <div className={mainClass}>
        <div className="col mt-5 offset-4">
          <div className="d-flex">
            <h1 className="studio">Studio</h1>
            <div className="learning-album d-flex">
              <div className="vertical-seperator mx-2">&nbsp;</div>
              <span>Learning Album</span>
            </div>
          </div>
          <h2 className="text unauthorized mt-3">Inactive</h2>
          <h5 className="text pt-3">Studio access has been deactivated.</h5>
          <h5 className="text pt-3">
            Studio is used by Verizon designers
            <br />
            to create / author content for the Edge
            <br />
            Learning Album application.
          </h5>
          <h5 className="text pt-3">
            <u>STOP!</u> If you are looking to review or deliver the
            <br />
            content, please ask the author(s) for the Edge URL
            <br />
            instead and DO NOT request access.
          </h5>
          <h5 className="text pt-3">
            If you are creating / authoring content, continue by
            <br />
            selecting Request Access below.
          </h5>
          <p className="text mt-3">
            NOTE: Clicking the button below will create your profile, but it
            will not be
            <br />
            approved until you complete the form correctly on the next page.
            <br />
            Failure to complete the form within 7 days will result in a denial
            <br />
            message.
          </p>
          <button
            className="btn btn-primary btn-lg my-2 request-access"
            onClick={() => {
              !isButtonPressed && submitAccess();
            }}
          >
            Request Access
            <i className="pl-4 fa fa-angle-right fa-lg" />
          </button>
        </div>
      </div>
      <div
        className="fixed-bottom ml-4 pl-4 mb-4 pb-4 mr-auto"
        style={{ width: 230 }}
      >
        <img src={logo} className="logo" width="200" height="auto" />
      </div>
    </Fragment>
  );
};

Inactive.propTypes = propTypes;

export default Inactive;
