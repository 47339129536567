export const topicEditPath = ({
  modulId,
  topicId,
  showPublishableContent = false,
  includeArchived = false
}) => {
  if (includeArchived) {
    return `/moduls/${modulId}/topics/${topicId}/edit.json?show_publishable_content=true&include_archived=true`;
  }
  return `/moduls/${modulId}/topics/${topicId}/edit.json${
    showPublishableContent ? "?show_publishable_content=true" : ""
  }`;
};
export const topicPath = ({ modulId, topicId }) => {
  return `/moduls/${modulId}/topics/${topicId}`;
};

export const quizEditPath = ({
  modulId,
  quizId,
  showPublishableContent = false,
  includeArchived = false
}) => {
  if (includeArchived) {
    return `/moduls/${modulId}/quizzes/${quizId}/edit.json?show_publishable_content=true&include_archived=true`;
  }
  return `/moduls/${modulId}/quizzes/${quizId}/edit.json${
    showPublishableContent ? "?show_publishable_content=true" : ""
  }`;
};
export const quizPath = ({ modulId, quizId }) => {
  return `/moduls/${modulId}/quizzes/${quizId}`;
};

export const assignmentEditPath = ({
  modulId,
  assignmentId,
  showPublishableContent = false,
  includeArchived = false
}) => {
  if (includeArchived) {
    return `/moduls/${modulId}/assignments/${assignmentId}/edit.json?show_publishable_content=true&include_archived=true`;
  }
  return `/moduls/${modulId}/assignments/${assignmentId}/edit.json${
    showPublishableContent ? "?show_publishable_content=true" : ""
  }`;
};

export const assignmentPath = ({ modulId, assignmentId }) => {
  return `/moduls/${modulId}/assignments/${assignmentId}`;
};

export const activityEditPath = ({
  modulId,
  activityId,
  showPublishableContent = false,
  includeArchived = false
}) => {
  if (includeArchived) {
    return `/moduls/${modulId}/activities/${activityId}/edit.json?show_publishable_content=true&include_archived=true`;
  }
  return `/moduls/${modulId}/activities/${activityId}/edit.json${
    showPublishableContent ? "?show_publishable_content=true" : ""
  }`;
};

export const activityPath = ({ modulId, activityId }) => {
  return `/moduls/${modulId}/activities/${activityId}`;
};

export const previewPath = ({ albumId, sectionId, modulId, pageId }) => {
  return `/albums/${albumId}/sections/${sectionId}/modules/${modulId}/pages/${pageId}/preview`;
};

export const backToModulePath = ({ albumId, sectionId, modulId }) => {
  return `/albums/${albumId}/sections/${sectionId}/modules?modul_id=${modulId}`;
};

export const backToContentPath = ({ albumId }) => {
  return `/albums/${albumId}/content`;
};

export const pagePath = ({ albumId, sectionId, modulId, pageId }) => {
  return `/albums/${albumId}/sections/${sectionId}/modules/${modulId}/pages/${pageId}`;
};

export const oldPagePath = ({ modulId, type, pageId }) => {
  return `/moduls/${modulId}/${type}/${pageId}`;
};

export const sectionEditPath = ({ path }) => {
  return `${path}/edit.json`;
};

export const tocPath = ({ albumId }) => {
  return `/albums/${albumId}/toc.json`;
};

export const modulePath = ({ sectionId, moduleId }) => {
  return `/sections/${sectionId}/moduls/${moduleId}`;
};

export const chunkPath = ({ topicId, id }) => {
  return `/topics/${topicId}/chunks/${id}`;
};

export const pollChunkPath = ({ topicId, id }) => {
  return `/topics/${topicId}/poll_chunks/${id}`;
};

export const prepPath = ({ setupId, id }) => {
  return `/setup_topics/${setupId}/preps/${id}`;
};

export const questionAnswersPath = ({ questionId }) => {
  return `/questions/${questionId}/answers.json`;
};

export const answerPath = ({ answerId }) => {
  return `/answers/${answerId}.json`;
};

export const pollQuestionAnswersPath = ({ pollChunkId }) => {
  return `/poll_chunks/${pollChunkId}/poll_answers`;
};

export const pollAnswerPath = ({ pollAnswerId }) => {
  return `/poll_answers/${pollAnswerId}.json`;
};

export const sectionPath = ({ albumId, sectionId }) => {
  return `/albums/${albumId}/sections/${sectionId}`;
};

export const createSectionPath = ({ albumId }) => {
  return `/albums/${albumId}/sections?section%5Bposition%5D=`;
};

export const moduleTocPath = ({ albumId, sectionId }) => {
  return `/albums/${albumId}/sections/${sectionId}/modules`;
};

export const publishPath = ({ albumId }) => {
  return `/albums/${albumId}/publish`;
};

export const pushPath = ({ albumId }) => {
  return `/albums/${albumId}/publish`;
};

/**
 * Archived Content Paths
 */

export const getArchivePath = (scope, id) => {
  switch (scope) {
    case "Albums":
      return `/album_archives`;
    case "Sections":
      return `/albums/${id}/section_archives.json`;
    case "Modules":
      return `/sections/${id}/modul_archives.json`;
    case "Pages":
      return `/moduls/${id}/page_archives.json`;
  }
};

/**
 * Reactivate Content Paths
 */

export const getReactivatePath = (scope, id) => {
  switch (scope) {
    case "Albums":
      return `/reactivate_albums`;
    case "Sections":
      return `/reactivate_sections`;
    case "Modules":
      return `/reactivate_moduls`;
    case "Pages":
      return `/reactivate_pages`;
  }
};

/**
 * Setup Paths
 */

export const setupEditPath = ({ setupId, showPublishableContent = false }) => {
  return `/setups/${setupId}/edit${
    showPublishableContent ? "?show_publishable_content=true" : ""
  }`;
};

export const setupUpdatePath = ({ setupId }) => {
  return `/setups/${setupId}`;
};

/**
 * Reuse Modal Paths
 */

export const reuseListModalId = ({ modulId, pageId, withHash }) => {
  if (withHash) {
    return `#reuseList${modulId}${pageId}`;
  } else {
    return `reuseList${modulId}${pageId}`;
  }
};

/**
 * Clipboard Modal Paths
 */

export const getModalId = ({ type, id, withHash }) => {
  if (withHash) {
    return `#clipboard_modal_for_${type}_${id}`;
  } else {
    return `clipboard_modal_for_${type}_${id}`;
  }
};

/**
 * Archive Modal Paths
 */

export const getArchiveModalId = ({ type, id, withHash }) => {
  if (withHash) {
    return `#archive_modal_for_${type}_${id}`;
  } else {
    return `archive_modal_for_${type}_${id}`;
  }
};

/**
 * Set pages to Review
 */

export const setParentReviewPath = ({ type, id }) => {
  return `/${type}/${id}/publishables`;
};

export const setReviewPath = ({ type, id }) => {
  return `/publishables/${type}/${id}/status`;
};

/**
 * Set mutliple pages to a new status
 */

export const setPagesStatusPath = () => {
  return `/publishables/all_status`;
};

/**
 * Clear page alerts and (optionally) set pages
 * associated with the alert(s) to a new status:
 * POST /alerts/resolve_all
 *  {
 *    alert_ids: [123, 456, 789],
 *    to_status: 'Ready'
 *  }
 */

export const resolveAllAlertsPath = () => {
  return `/alerts/resolve_all`;
};

/**
 * Media Tab Paths
 */

export const mediaUploadPath = () => {
  return `/uploads.json`;
};

export const mediaPath = ({ mediaId }) => {
  return `/uploads/${mediaId}.json`;
};

export const changeMediaPath = ({ mediaId, mediaType }) => {
  const type = mediaType.toLowerCase();
  return `/uploads/${mediaId}/update_${type}.json`;
};

export const mediaListPath = ({ albumId }) => {
  return `/albums/${albumId}/media.json`;
};

export const albumInfoPath = ({ albumId }) => {
  return `/albums/${albumId}.json`;
};

export const albumEditPath = ({ albumId }) => {
  return `/albums/${albumId}.json`;
};

export const deleteAllOrphanedMediaPath = albumId => {
  return `/albums/${albumId}/uploads/destroy_unused`;
};

/**
 * User and Profile Paths
 */

export const userPath = ({ userId }) => {
  return `/users/${userId}.json`;
};

export const profilePath = ({ userId }) => {
  return `/profiles/${userId}.json`;
};

/**
 * Publish Paths
 */

export const getPagesByStatusPath = ({ albumId }) => {
  return `/albums/${albumId}/publishables`;
};

export const publishVersionPath = ({ albumId }) => {
  return `/albums/${albumId}/working_version`;
};

export const proceedPublishPath = ({ albumId }) => {
  return `/albums/${albumId}/published_version`;
};

/**
 * Album Services Paths
 */

export const albumServicesPath = ({ albumId }) => {
  return {
    content: `/albums/${albumId}/content`,
    media: `/albums/${albumId}/media`,
    permissions: `/albums/${albumId}/permissions`,
    contributors: `/albums/${albumId}/contributors`,
    invite: `/albums/${albumId}/invite`,
    updates: `/albums/${albumId}/updates`,
    settings: `/albums/${albumId}/settings`,
    review: `/albums/${albumId}/review`,
    alerts: `/albums/${albumId}/alerts`,
    duplicate_permissions: `/albums/${albumId}/duplicate_permissions`
  };
};

export const requestsCountPath = ({ albumId }) => {
  return `/albums/${albumId}/access_request_count`;
};

/**
 * Permissions Paths
 */

export const requestsPagePath = ({ albumId }) => {
  return `/albums/${albumId}/permissions`;
};

export const usersPagePath = ({ albumId }) => {
  return `/albums/${albumId}/contributors`;
};

export const requestsPath = ({ albumId }) => {
  return `/albums/${albumId}/access_requests`;
};

export const accessPath = ({ albumId, type }) => {
  if (type == "approve") {
    return `/albums/${albumId}/access_requests/approve`;
  }
  return `/albums/${albumId}/access_requests/deny`;
};

export const usersPath = ({ albumId }) => {
  return `/albums/${albumId}/users`;
};

export const removeAccessPath = ({ albumId }) => {
  return `/albums/${albumId}/users/remove`;
};

export const invitationsPath = ({ albumId }) => {
  return `/albums/${albumId}/invitations`;
};

export const acceptAlbumInvitationPath = invitation_id => {
  return `/album_invitations/${invitation_id}/accept_on_behalf_of_user`;
};

export const downgradeRolePath = ({ albumId }) => {
  return `/albums/${albumId}/users/role`;
};

export const makeOwnerPath = ({ albumId }) => {
  return `/albums/${albumId}/owner`;
};

export const invitePath = ({ albumId }) => {
  return `/albums/${albumId}/invite`;
};

export const duplicatePermissionsPath = ({ albumId }) => {
  return `/albums/${albumId}/duplicate_permissions`;
};

export const copyPermissionsPath = ({ albumId }) => {
  return `/albums/${albumId}/copy_album_users`;
};

export const lookaheadPath = ({ type, albumId, query }) => {
  if (type == "Facilitator") {
    return `/albums/${albumId}/invitable_facilitators?query=${query}&album_id=${albumId}`;
  } else if (type == "Author") {
    return `/albums/${albumId}/invitable_authors?query=${query}&album_id=${albumId}`;
  } else {
    return `/albums/${albumId}/invitable_approvers?query=${query}&album_id=${albumId}`;
  }
};

export const inviteUsersPath = ({ albumId }) => {
  return `/albums/${albumId}/invites`;
};

/**
 * Updates Paths
 */

// Page Routes
export const updatesPath = ({ albumId }) => {
  return `/albums/${albumId}/updates`;
};

export const updatesPreviousPath = ({ albumId }) => {
  return `/albums/${albumId}/updates/previous_versions`;
};

export const updatesPreviousLink = ({ pathname }) => {
  return `${pathname}/previous_versions`;
};

export const updatesPreviousVersionLink = ({ pathname, id }) => {
  return `${pathname}/previous_versions/versions/${id}`;
};

export const updatesVersionLink = ({ pathname, id }) => {
  return `${pathname}/versions/${id}`;
};

// API Endpoints
export const updatesLatestPath = ({ albumId }) => {
  return `/albums/${albumId}/versions/latest`;
};

export const versionPath = ({ id }) => {
  return `/album_versions/${id}`;
};

export const previousMajorVersionsPath = ({ albumId }) => {
  return `/albums/${albumId}/versions/previous`;
};

export const previousPublishesPath = ({ id }) => {
  return `/album_versions/${id}/siblings`;
};

export const albumVersionHistoryPath = ({ albumId }) => {
  return `/albums/${albumId}/versions/history_export`;
};

/**
 * Preview Pagination Paths
 */

export const previewPaginationPath = ({ id }) => {
  return `/pages/${id}/proximal`;
};

export const setupPreviewPaginationPath = ({ id }) => {
  return `/setups/${id}/proximal`;
};

/**
 * Studio LogIn
 */

export const signInPath = ({ currentHostname }) => {
  const appName = window.location.href.replace(
    /(https?:\/\/)?([^?&/]+).*/i,
    "$2"
  );

  return `https://auth.learningalbum.vzw.com/?app=${appName}&callback=%2Fsaml_login`;
};

/**
 * Prep Icon Paths
 */

export const prepIconPath = () => {
  return {
    empty: "/img/labels/Prep/Empty@1x.png",
    draft: "/img/labels/Prep/Draft@1x.png",
    review: "/img/labels/Prep/Review@1x.png",
    ready: "/img/labels/Prep/Ready@1x.png",
    published: "/img/labels/Prep/Published@1x.png"
  };
};

/**
 * Library and Explore Paths
 */

export const createAlbumPath = () => {
  return "/albums/new";
};

export const globalSearchPath = context => {
  if (context === "archived") {
    return `/search/global/?only_archived=true`;
  } else {
    return `/search/global`;
  }
};

export const searchAndReplacePath = () => {
  return `/search/replace`;
};

/**
 * System Admin Paths
 */

export const studioUsersPagePath = () => {
  return `/studio/users`;
};

export const studioRequestsPagePath = () => {
  return `/studio/access_requests`;
};

export const studioUsersPath = () => {
  return `/users.json`;
};

export const uxAccessRequestsPath = () => {
  return `/ux_access_requests`;
};

export const uxAccessPath = type => {
  if (type == "approve") {
    return `/access_requests/approve`;
  }
  return `/access_requests/deny`;
};

export const removeStudioUserPath = ({ studioUser }) => {
  return `/users/${studioUser.id}/remove`;
};

export const approveStudioUserPath = ({ studioUser }) => {
  return `/users/${studioUser.id}/approve`;
};

export const removeAvatarPath = ({ studioUser }) => {
  return `/users/${studioUser.id}/remove_avatar`;
};

export const workdayResendPath = () => {
  return `/resend_completions`;
};

/**
 * Export Album Data Paths
 */

export const exportAlbumPath = ({ albumId }) => {
  return `/albums/${albumId}/versions/latest/toc_export`;
};

export const exportAlbumReusesReportPath = ({ albumId }) => {
  return `/albums/${albumId}/reuses_report.xlsx`;
};

/**
 * Export Album Data Paths
 */

export const signOutPath = () => "/auth/users/sign_out";
export const ssoSignOutPath = () => "https://ilogin.verizon.com/sso_logout.jsp";
export const timeoutPath = () => "/timeout";

/**
 * Access Request Form Path
 */

export const accessRequestFormPath = () =>
  "https://learning.verizon.com/album/Lists/Learning%20Album%20Studio%20Access%20Request%20Form/NewForm.aspx?Source=https://learning.verizon.com/album/SitePages/Access%20Form%20Complete.aspx";

/**
 * Notifications Session Token Path
 */

export const getTokenPath = () => {
  return `/session/token`;
};

/**
 *  Path for public config items (e.g., GraphQL API path)
 */

export const publicConfigPath = () => {
  return `/public_config`;
};

/**
 * Mock user paths
 */

export const exitMockUserPath = () => {
  return `/admin/users/switch_back`;
};

export const becomeUserPath = ({ studioUser }) => {
  return `/admin/users/${studioUser.id}/become`;
};

/**
 *  Global Permissions path
 */

export const globalPermissionsRequestsPath = () => {
  return `/global/access_requests`;
};

export const globalPermissionsAccessPath = type => {
  if (type === "approve") {
    return `/album_access_requests/approve`;
  } else {
    return `/album_access_requests/deny`;
  }
};
