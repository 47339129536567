import React, { useEffect } from "react";

const Timeout = props => {
  useEffect(() => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("expires");
  }, []);

  return (
    <div
      className="d-flex justify-content-center align-items-center flex-column"
      style={{ height: "100%" }}
    >
      <h1>Application Timed Out</h1>

      <div style={{ marginTop: 20, marginBottom: 10 }}>
        Due to inactivity, your session has ended and you will need to sign back
        in.{" "}
      </div>
      <div style={{ marginTop: 10, marginBottom: 40 }}>
        {" "}
        Once you sign back in, you will be redirected back to the last page you
        were on.
      </div>
      <div
        className="btn btn-primary"
        onClick={() => {
          window.location.href = document.referrer;
        }}
      >
        Continue to Sign In Page
      </div>
    </div>
  );
};

export default Timeout;
